<div class="container rr-block">
  <div [class.grey]="grey()" [class.small]="small()" class="d-flex loading-wrapper">
    <div class="loader">
      <ul>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div class="wineglass left">
        <div class="top"></div>
      </div>
      <div class="wineglass right">
        <div class="top"></div>
      </div>
    </div>
  </div>
</div>

