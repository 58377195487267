import {Component, input} from '@angular/core';

@Component({
  selector: 'app-loading-icon',
  templateUrl: './loading-icon.component.html',
  styleUrls: ['./loading-icon.component.scss'],
  standalone: true,
})
export class LoadingIconComponent {

  readonly grey = input(false);

  readonly small = input(false);

}
